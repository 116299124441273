import IconButton from './IconButton';
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { showConfirm } from './Messages';

export default class Image extends Component {
   constructor(props) {
      super(props);
      this.state = { base64: null };
   }

   componentDidMount() {
      this.load();
   }

   load = () => {
      if (this.props.image && this.props.image.nome) {
         this.props.api.get('/file?filename=' + this.props.image.nome, true, 'arraybuffer').then((data) => {
            const base64 = Buffer.from(data, 'binary').toString('base64');
            this.setState({ base64: 'data:image/png;base64, ' + base64 });
         });
      }
   };

   render() {
      return (
         <React.Fragment>
            <Form.Label
               style={{ cursor: 'pointer', margin: 0, textAlign: 'center',  width: this.props.width}}
               title={
                  this.props.image && this.props.image ? this.props.lang.alterarImagem : this.props.lang.carregarImagem
               }
            >
               <Form.File
                  accept={'image/png, image/jpeg'}
                  onChange={(e) => this.onChange(e)}
                  style={{ position: 'absolute', top: -1000 }}
                  tabIndex={-1}
               />
               {this.props.image && this.props.image.nome ? (
                  // <Img alt='' url={() => this.state.base64} width={this.props.width} />
                  <img
                     alt=''
                     src={this.state.base64}
                     height={this.props.height}
                     width={this.props.width}
                     style={{ maxHeight: '100%', maxWidth: '100%' }}
                     onLoad={() => {
                        if (this.props.onLoad) {
                           this.props.onLoad(this.state.base64);
                        }
                     }}
                  />
               ) : (
                  <ImgNone alt='' width={this.props.width}  >
                     <IconButton style={{ color: '#999', margin: 'auto', height: this.props.height }} icon={faImage} />
                  </ImgNone>
               )}
            </Form.Label>
            {this.props.image && this.props.image.nome && (
               <div
                  style={{
                     color: 'initial',
                     position: 'absolute',
                     right: 8,
                     bottom: -30,
                     fontSize: 20,
                     cursor: 'pointer',
                     width: this.props.width,
                  }}
                  title={this.props.lang.excluirImagem}
                  onClick={(e) => this.excluir(e)}
               >
                  <IconButton icon={faTrashAlt} style={{ margin: 'auto' }} />
               </div>
            )}
         </React.Fragment>
      );
   }

   onChange = (event) => {
      var self = this;
      var tg = event.target;
      if (event.target.files.length > 0) {
         let file = event.target.files[0];
         var fileReader = new FileReader();
         fileReader.addEventListener(
            'load',
            function () {            
               var input = {
                  tipo: file.type,
                  base64: fileReader.result,
               };
               self.props.api.post('/file', input).then((result) => {
                  if (self.props.onChange) {
                     self.props.onChange({
                        id: result.id,
                        nome: result.nome,
                        base64: fileReader.result,
                     });
                  }
                  tg.value = null;
                  self.load();
               });
            },
            false
         );
         fileReader.readAsDataURL(file);
      }
   };

   excluir = (event) => {
      var tg = event.target;
      showConfirm(this.props.lang.desejaRealmenteExcluirAImagem, () => {
         if (this.props.onChange) {
            this.props.onChange(null);
         }
         tg.value = null;
         this.setState({ base64: null });
      });
   };
}

const ImgNone = styled.div`
   object-fit: cover;
   height: ${(props) => (props.height ? props.height : '100%')};
   max-height: ${(props) => (props.maxHeight ? props.maxHeight : '100%')};
   width: ${(props) => (props.width ? props.width : '100%')};
   font-size: 110px;
   line-height: 0px;

   i {
      text-decoration: none;
      color: #888;
   }
`;
